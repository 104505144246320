import { createRouter, createWebHistory } from 'vue-router';
import i18n from '../i18n';
import auth from './middleware/auth';
import mobile from './middleware/mobile';

const routes = [
    {
        path: '/login',
        name: 'login-es',
        component: () => import('@/views/Login/Login.vue'),
        beforeEnter: [mobile],
    },
    {
        path: '/:locale/login',
        name: 'login',
        component: () => import('@/views/Login/Login.vue'),
        beforeEnter: [mobile],
    },
    {
        path: '/',
        component: () => import('@/layouts/MainLayout.vue'),
        children: [
            {
                path: '',
                name: 'home',
                beforeEnter: [auth, mobile],
                redirect: '/mi-cuenta',
            },
            {
                path: '/mi-cuenta/cambiar-plan',
                component: () => import('@/views/MiCuenta/ChangePlan/ChangePlan.vue'),
                beforeEnter: [auth, mobile],
                children: [
                    {
                        path: '',
                        name: 'cambiar-plan',
                        component: () => import('@/views/MiCuenta/ChangePlan/ChangePlanPanel.vue'),
                        beforeEnter: [auth, mobile],
                    },
                ],
            },
            {
                path: '/mi-cuenta/sostenibilidad',
                component: () => import('@/views/MiCuenta/Sustainability/Index.vue'),
                beforeEnter: [auth, mobile],
                children: [
                    {
                        path: '',
                        name: 'sostenibilidad',
                        component: () =>
                            import('@/views/MiCuenta/Sustainability/SustainabilityView.vue'),
                        beforeEnter: [auth, mobile],
                    },
                    {
                        path: 'estrategia-aquaservice',
                        name: 'estrategia-aquaservice',
                        component: () => import('@/views/MiCuenta/Sustainability/StrategyView.vue'),
                        beforeEnter: [auth, mobile],
                    },
                ],
            },
            {
                path: '/mi-cuenta/coffeeb',
                component: () => import('@/views/MiCuenta/CoffeeB/CoffeeBPanel.vue'),
                beforeEnter: [auth, mobile],
                children: [
                    {
                        path: '',
                        name: 'coffeeb',
                        component: () => import('@/views/MiCuenta/CoffeeB/CoffeeB.vue'),
                        beforeEnter: [auth, mobile],
                    },
                ],
            },
            {
                path: '/mi-cuenta',
                component: () => import('@/views/MiCuenta/MiCuenta.vue'),
                beforeEnter: [auth, mobile],
                children: [
                    {
                        path: '',
                        name: 'mi-cuenta',
                        component: () => import('@/views/MiCuenta/MiCuentaPanel.vue'),
                        beforeEnter: [auth, mobile],
                    },
                    {
                        path: 'mis-direcciones',
                        name: 'mis-direcciones',
                        component: () =>
                            import('@/views/MiCuenta/MisDirecciones/MisDireccionesPanel.vue'),
                        beforeEnter: [auth, mobile],
                    },
                    {
                        path: 'metodos-pago',
                        name: 'metodos-pago',
                        component: () => import('@/views/MiCuenta/MetodosPago/PaymentMethods.vue'),
                        beforeEnter: [auth, mobile],
                    },
                    {
                        path: 'nueva-tarjeta',
                        name: 'nueva-tarjeta',
                        component: () => import('@/views/MiCuenta/MetodosPago/AddCard.vue'),
                        beforeEnter: [auth, mobile],
                    },
                    {
                        path: 'datos-facturacion',
                        name: 'datos-facturacion',
                        component: () => import('@/views/MiCuenta/BillingInfo/BillingInfo.vue'),
                        beforeEnter: [auth, mobile],
                    },
                ],
            },
            {
                path: '/reparto-programado',
                name: 'reparto-programado',
                component: () => import('@/views/Repartos/RepartoProgramadoPanel.vue'),
                beforeEnter: [auth, mobile],
            },
            {
                path: '/reparto-urgente',
                name: 'reparto-urgente',
                component: () => import('@/views/Repartos/RepartoUrgentePanel.vue'),
                beforeEnter: [auth, mobile],
            },
            {
                path: '/facturas',
                component: () => import('@/views/Invoices/Invoices.vue'),
                beforeEnter: [auth, mobile],
                children: [
                    {
                        path: '',
                        name: 'facturas',
                        component: () => import('@/views/Invoices/InvoicesPanel.vue'),
                        beforeEnter: [auth, mobile],
                    },
                    {
                        path: 'pagar-ahora',
                        name: 'pagar-ahora',
                        component: () => import('@/views/Invoices/PayInvoicesNowPanel.vue'),
                        beforeEnter: [auth, mobile],
                    },
                ],
            },
            {
                path: '/entregas',
                component: () => import('@/views/DeliveryHistory/DeliveryHistory.vue'),
                beforeEnter: [auth, mobile],
                children: [
                    {
                        path: '',
                        name: 'entregas',
                        component: () => import('@/views/DeliveryHistory/DeliveryHistoryPanel.vue'),
                        beforeEnter: [auth, mobile],
                    },
                ],
            },
            {
                path: '/aquamigo',
                component: () => import('@/views/Aquamigo/Aquamigo.vue'),
                beforeEnter: [auth, mobile],
                children: [
                    {
                        path: '',
                        name: 'aquamigo',
                        component: () => import('@/views/Aquamigo/AquamigoForm.vue'),
                        beforeEnter: [auth, mobile],
                    },
                    {
                        path: 'invitaciones',
                        name: 'aquamigo-invitaciones',
                        component: () =>
                            import('@/views/Aquamigo/AquamigoInvitations/AquamigoInvitations.vue'),
                        beforeEnter: [auth, mobile],
                    },
                    {
                        path: 'invitacion/:id',
                        name: 'aquamigo-invitacion-id',
                        component: () =>
                            import(
                                '@/views/Aquamigo/AquamigoInvitations/AquamigoInvitation/AquamigoInvitation.vue'
                            ),
                        beforeEnter: [auth, mobile],
                    },
                ],
            },
            {
                path: '/atencion-cliente',
                name: 'atencion-cliente',
                component: () => import('@/views/Atencion/Atencion.vue'),
                beforeEnter: [auth, mobile],
            },
            {
                path: '/administrar-perfiles',
                name: 'administrar-perfiles',
                component: () => import('@/views/Profiles/ManageProfiles.vue'),
                beforeEnter: [auth, mobile],
            },
        ],
    },
    {
        path: '/mobile',
        name: 'mobile',
        component: () => import('@/views/AppMobile.vue'),
    },
    {
        path: '/external-coffeeb',
        name: 'external-coffeeb',
        component: () => import('@/views/MiCuenta/CoffeeB/CoffeeB.vue'),
    },
    {
        path: '/external-coffeeb/:bannerType',
        name: 'external-coffeeb-ka-ma',
        component: () => import('@/views/MiCuenta/CoffeeB/CoffeeB.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        component: () => import('@/views/Error404.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

const isAuthenticated = () => {
    return !!localStorage.getItem('token');
};

router.beforeEach((to, from, next) => {
    const { locale } = to.params;

    if (locale && i18n.global.availableLocales.includes(locale)) {
        i18n.global.locale = locale;
    } else if (!locale) {
        i18n.global.locale = 'es';
    } else {
        i18n.global.locale = i18n.global.fallbackLocale;
    }

    if (to.name !== 'login' && !isAuthenticated()) {
        next({ name: 'login', params: { locale: i18n.global.locale } });
    } else {
        next();
    }
});

router.onError((error) => {
    if (error.message.includes('Failed to fetch dynamically imported module')) {
        const nextRoute = router.history?.pending?.fullPath;
        if (nextRoute) {
            window.location.href = nextRoute;
        }
    }
});

export default router;
