import { useSentry } from './sentry';
import '@/sass/app.scss';
import { vOnClickOutside } from '@vueuse/components';
import { createApp } from 'vue';
import filters from './filters';
import plugins from './plugins';
import i18n from './i18n';

window.$ = window.jQuery = $;

import App from '@/views/App';
const app = createApp(App);
plugins.forEach(({ plugin, options }) => app.use(plugin, ...options));
useSentry(app);

app.directive('click-outside', vOnClickOutside);
app.config.globalProperties.$filters = filters;
app.use(i18n);
app.mount('#app');
